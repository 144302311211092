 $color1 : #F9FBFC;
 $color2 : #36365A;
 $color3 : #D1D6D6;
 $color4 : #B6C4C7;
 $color5 : #03B9E6;//#3ECC75 #2196F3;
 $color6 : #84DCA3;
 $color6_1 : #03B9E6;
 $ios-dark: rgb(50, 50, 50);
 $ios-light: rgb(246, 246, 246);
 $android: rgb(130, 197, 92);

 $verde_sim:#03B9E6;
// @import "components/extend";
// @import "components/resect";
// @import "components/animate";
// @import "components/alert";
// @import "components/header";
// @import "components/services";
// @import "components/efet_icom";
// @import "components/profolio";
// @import "components/carousel";
// @import "components/global";
// @import "components/the_studio";
// @import "components/form";
// @import "components/owl";
// @import "components/icom";
// @import "components/page";

// @import "components/footer";

.scrollspy .services-container, .like_see .like_see-container {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: table;
  padding-bottom: 60px;
  border-bottom: 1px solid #D1D6D6; }

.section_studio img, .section_page .sub_background_content img {
  width: 100%;
  -webkit-transition: all 2s;
  transition: all 2s; }
  @media screen and (min-width: 1225px) {
    .section_studio img, .section_page .sub_background_content img {
      -webkit-transform: translate(0%, -25%);
      -ms-transform: translate(0%, -25%);
      transform: translate(0%, -25%); } }
  @media screen and (max-width: 1225px) {
    .section_studio img, .section_page .sub_background_content img {
      -webkit-transform: translate(-25%, -25%);
      -ms-transform: translate(-25%, -25%);
      transform: translate(-25%, -25%);
      min-width: 1500px; } }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v15/0eC6fl06luXEYWpBSJvXCBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v15/Fl4y0QdOxyyTHEGMXX8kcRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v15/-L14Jk06m6pUHB-5mXQQnRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v15/I3S1wsgSg9YCurV6PUkTORJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v15/NYDWBdD4gIq26G5XYbHsFBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v15/Pru33qjShpZSmG3z6VYwnRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(//fonts.gstatic.com/s/roboto/v15/Hgo13k-tfSpn0qi1SFdUfVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v15/ek4gzZ-GeXAPcSbHtCeQI_esZW2xOQ-xsNqO47m55DA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v15/mErvLBYg_cXG3rLvUsKT_fesZW2xOQ-xsNqO47m55DA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v15/-2n2p-_Y08sg57CNWQfKNvesZW2xOQ-xsNqO47m55DA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v15/u0TOpm082MNkS5K0Q4rhqvesZW2xOQ-xsNqO47m55DA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v15/NdF9MtnOpLzo-noMoG0miPesZW2xOQ-xsNqO47m55DA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v15/Fcx7Wwv8OzT71A3E1XOAjvesZW2xOQ-xsNqO47m55DA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(//fonts.gstatic.com/s/roboto/v15/CWB0XYA8bzo0kSThX0UTuA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(//fonts.gstatic.com/s/roboto/v15/ZLqKeelYbATG60EpZBSDyxJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(//fonts.gstatic.com/s/roboto/v15/oHi30kwQWvpCWqAhzHcCSBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(//fonts.gstatic.com/s/roboto/v15/rGvHdJnr2l75qb0YND9NyBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(//fonts.gstatic.com/s/roboto/v15/mx9Uck6uB63VIKFYnEMXrRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(//fonts.gstatic.com/s/roboto/v15/mbmhprMH69Zi6eEPBYVFhRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(//fonts.gstatic.com/s/roboto/v15/oOeFwZNlrTefzLYmlVV1UBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(//fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v15/77FXFjRbGzN4aCrSFhlh3hJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v15/isZ-wbCXNKAbnjo6_TwHThJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v15/UX6i4JxQDm3fVTc1CPuwqhJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v15/jSN2CGVDbcVyCnfJfjSdfBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v15/PwZc-YbIL414wB9rB1IAPRJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v15/97uahxiqZRoncBaCEI3aWxJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(//fonts.gstatic.com/s/roboto/v15/d-6IYplOFocCacKzxwXSOFtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

body {
  margin: 0;
  height: 100%; }

a {
  text-decoration: none; }

a img {
  border: 0px; }

::-moz-selection {
  background-color: #2196F3;
  color: #fff; }

::selection {
  background-color: #2196F3;
  color: #fff; }

* {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

a, buttom {
  outline: none; }

input[type="checkbox"],
input[type="radio"] {
  display: none; }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  .animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s; }
  .animated.bounceIn, .animated.bounceOut {
    -webkit-animation-duration: .75s;
    animation-duration: .75s; }
  .animated.flipOutX, .animated.flipOutY {
    -webkit-animation-duration: .75s;
    animation-duration: .75s; }
  .animated.flip {
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation-name: flip;
    animation-name: flip; }

@-webkit-keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

@keyframes bounce {
  0%, 20%, 53%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0); }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0); } }

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom; }

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  -webkit-animation-name: flash;
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand; }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); } }

.shake {
  -webkit-animation-name: shake;
  animation-name: shake; }

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg); }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.tada {
  -webkit-animation-name: tada;
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none; }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

@-webkit-keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes bounceIn {
  0%, 20%, 40%, 60%, 80%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97); }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

@-webkit-keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

@-webkit-keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInLeft {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

@-webkit-keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInRight {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0); }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0); }
  100% {
    -webkit-transform: none;
    transform: none; } }

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

@-webkit-keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes bounceInUp {
  0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig; }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig; }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig; }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig; }

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX; }

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY; }

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px); }
  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY; }

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes lightSpeedIn {
  0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1; }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOut {
  0% {
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1; }
  100% {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight; }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

@-webkit-keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInDown {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown; }

@-webkit-keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInLeft {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft; }

@-webkit-keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInRight {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight; }

@-webkit-keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomInUp {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp; }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown; }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft; }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight; }

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp; }

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown; }

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

@keyframes slideOutDown {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown; }

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft; }

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

@keyframes slideOutRight {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight; }

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

@keyframes slideOutUp {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  100% {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

.alert {
  padding: 15px;
  margin-bottom: 18px;
  border: 1px solid transparent;
  -webkit-border-radius: 2px;
  border-radius: 2px; }

.alert h4 {
  margin-top: 0;
  color: inherit; }

.alert .alert-link {
  font-weight: bold; }

.alert > p,
.alert > ul {
  margin-bottom: 0; }

.alert > p + p {
  margin-top: 5px; }

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px; }

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit; }

.alert-success {
  background-color: #4caf50;
  border-color: transparent;
  color: #ffffff; }

.alert-success hr {
  border-top-color: transparent; }

.alert-success .alert-link {
  color: #e6e6e6; }

.alert-info {
  background-color: #2196f3;
  border-color: transparent;
  color: #ffffff; }

.alert-info hr {
  border-top-color: transparent; }

.alert-info .alert-link {
  color: #e6e6e6; }

.alert-warning {
  background-color: #ffc107;
  border-color: transparent;
  color: #ffffff; }

.alert-warning hr {
  border-top-color: transparent; }

.alert-warning .alert-link {
  color: #e6e6e6; }

.alert-danger {
  background-color: #f44336;
  border-color: transparent;
  color: #ffffff; }

.alert-danger hr {
  border-top-color: transparent; }

.alert-danger .alert-link {
  color: #e6e6e6; }

.alert {
  padding-left: 30px;
  font-size: 13px; }

.alert span {
  cursor: pointer; }

.alert:not(.alert-dismissible) {
  padding-right: 30px; }

.alert.alert-dismissable {
  padding-right: 44px; }

.alert-inverse {
  background-color: #333333;
  border-color: transparent;
  color: #ffffff; }

.alert-inverse hr {
  border-top-color: transparent; }

.alert-inverse .alert-link {
  color: #e6e6e6; }

.growl-animated.alert-inverse {
  -webkit-box-shadow: 0 0 5px rgba(51, 51, 51, 0.5);
  box-shadow: 0 0 5px rgba(51, 51, 51, 0.5); }

.growl-animated.alert-info {
  -webkit-box-shadow: 0 0 5px rgba(33, 150, 243, 0.5);
  box-shadow: 0 0 5px rgba(33, 150, 243, 0.5); }

.growl-animated.alert-success {
  -webkit-box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); }

.growl-animated.alert-warning {
  -webkit-box-shadow: 0 0 5px rgba(255, 193, 7, 0.5);
  box-shadow: 0 0 5px rgba(255, 193, 7, 0.5); }

.growl-animated.alert-danger {
  -webkit-box-shadow: 0 0 5px rgba(244, 67, 54, 0.5);
  box-shadow: 0 0 5px rgba(244, 67, 54, 0.5); }

.alert-link {
  color: #fff !important; }

.close {
  float: right;
  font-size: 19.5px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20); }

.close:hover,
.close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50); }

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.sr-only {
  display: none; }

.sim-header {
  overflow: visible;
  max-height: 100px !important;
  position: fixed;
  z-index: 300;
  width: 100%;
  }
  .sim-header samp {
    color: #03B9E6;
    font-weight: 500 !important; }
  .sim-header.page {
    background: transparent none repeat scroll 0% 0%;
    -webkit-transition: all 2s;
    transition: all 2s;
    -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12); }
    .sim-header.page .sim-logo samp {
      color: white; }
    .sim-header.page .sim-navigation a {
      color: #fff; }
    @media screen and (max-width: 799px) {
      .sim-header.page .sim-navigation {
        background: rgba(16, 10, 10, 0.72) none repeat scroll 0% 0%; } }
    .sim-header.page .hamburger {
      background: #fff; }
  .sim-header .return {
    position: absolute;
    background: white none repeat scroll 0% 0%;
    width: 48px;
    height: 48px;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    top: 20px;
    left: 20px;
    -webkit-border-radius: 100%;
    border-radius: 100%; }
  .sim-header * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .sim-header .menu-open-button {
    float: left;
    width: 50px;
    height: 90px;
    position: relative; }
    @media screen and (min-width: 799px) {
      .sim-header .menu-open-button {
        display: none; } }
  .sim-header .sim-logo {
    float: left;
    width: auto;
    padding-left: 30px;
    padding-right: 10px; }
    .sim-header .sim-logo samp {
      font-weight: 300;
      font-size: 20px; }
    @media screen and (min-width: 1225px) {
      .sim-header .sim-logo {
        margin-left: 70px; } }
  .sim-header .sim-navigation {
    position: absolute;
    top: 0px; }
    @media screen and (min-width: 1225px) {
      .sim-header .sim-navigation {
        right: 100px; } }
    @media screen and (min-width: 800px) and (max-width: 1224px) {
      .sim-header .sim-navigation {
        right: 0px; } }
    @media screen and (max-width: 799px) {
      .sim-header .sim-navigation {
        left: -100%;
        width: 320px;
        height: 600px;
        background: white none repeat scroll 0% 0%;
        top: 90px;
        border-top: 1px solid #fafefa;
        -webkit-transition: all 2s;
        transition: all 2s; } }
    .sim-header .sim-navigation a {
      -webkit-transition: all 2s;
      transition: all 2s;
      color: #ffffff;
      font-weight: 700;
      font-size: 14px;
      display: inline-block;
      text-transform: uppercase;
      }
      @media screen and (min-width: 1000px) {
        .sim-header .sim-navigation a {
          padding-left: 20px;
          padding-right: 20px; } }
      @media screen and (max-width: 999px) {
        .sim-header .sim-navigation a {
          padding-left: 10px;
          padding-right: 10px; } }
      @media screen and (min-width: 800px) {
        .sim-header .sim-navigation a {
          height: 90px;
          line-height: 87px;
          border-bottom: 4px solid transparent; }
          .sim-header .sim-navigation a:hover {
            border-bottom: 4px solid #03B9E6; } }
      @media screen and (max-width: 799px) {
        .sim-header .sim-navigation a {
          width: 100%;
          display: block;
          float: left;
          text-align: center;
          font-size: 20px;
          line-height: 48px; } }
      .sim-header .sim-navigation a.active {
        color: #03B9E6;
        }
  .sim-header .hamburger {
    width: 25px;
    height: 3px;
    background: #363636;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12.5px;
    margin-top: -1.5px;
    -webkit-transition: -webkit-transform 200ms;
    transition: transform 200ms;
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  .sim-header .hamburger-1 {
    -webkit-transform: translate3d(0, -8px, 0);
    transform: translate3d(0, -8px, 0); }
  .sim-header .hamburger-2 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .sim-header .hamburger-3 {
    -webkit-transform: translate3d(0, 8px, 0);
    transform: translate3d(0, 8px, 0); }

.menu-open {
  display: none; }
  @media screen and (max-width: 799px) {
    .menu-open:checked + .sim-header .menu-open-button .hamburger-1 {
      -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
      transform: translate3d(0, 0, 0) rotate(45deg); }
    .menu-open:checked + .sim-header .menu-open-button .hamburger-2 {
      -webkit-transform: translate3d(0, 0, 0) scale(0.1, 1);
      transform: translate3d(0, 0, 0) scale(0.1, 1); }
    .menu-open:checked + .sim-header .menu-open-button .hamburger-3 {
      -webkit-transform: translate3d(0, 0, 0) rotate(-45deg);
      transform: translate3d(0, 0, 0) rotate(-45deg); }
    .menu-open:checked + .sim-header .sim-navigation {
      left: 0px; } }

.sim-be-together-section {
  position: relative;
  height: 100%;
  width: auto;
  max-height: 900px;
  background-size: cover;
  background: #1d2527; /* Old browsers */
  background: -moz-linear-gradient(45deg, #1d2527 0%, #380762 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #1d2527 0%,#380762 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #1d2527 0%,#380762 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2527', endColorstr='#380762',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  .sim-be-together-section #demo-canvas {
    width: 100%;
    height: 100%; }
  .sim-be-together-section canvas {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -webkit-animation: zoomIn 1.4s 1;
    animation: zoomIn 1.4s 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }
  .sim-be-together-section .sim-conten-text {
    width: 100%;
    position: absolute;
    text-align: center; }
    @media screen and (min-width: 1000px) {
      .sim-be-together-section .sim-conten-text {
        max-width: 1000px;
        height: 182px;
        overflow: hidden;
        left: -webkit-calc(50% - 500px);
        left: calc(50% - 500px);
        top: -webkit-calc(50% - 91px);
        top: calc(50% - 91px); } }
    @media screen and (max-width: 999px) {
      .sim-be-together-section .sim-conten-text {
        top: 200px; } }
  .sim-be-together-section .sim-slogan {
    top: 0px;
    width: 100%;
    color: #fff;
    margin-bottom: 20px; }
    @media screen and (min-width: 480px) {
      .sim-be-together-section .sim-slogan {
        font-size: 60px; } }
    @media screen and (min-width: 322px) and (max-width: 479px) {
      .sim-be-together-section .sim-slogan {
        font-size: 30px; } }
    @media screen and (max-width: 321px) {
      .sim-be-together-section .sim-slogan {
        font-size: 20px; } }
  .sim-be-together-section .sim-sub-slogan {
    top: 0px;
    width: 100%;
    color: #fff; }
    @media screen and (min-width: 480px) {
      .sim-be-together-section .sim-sub-slogan {
        font-size: 21px; } }
    @media screen and (min-width: 322px) and (max-width: 479px) {
      .sim-be-together-section .sim-sub-slogan {
        font-size: 18px; } }
    @media screen and (max-width: 321px) {
      .sim-be-together-section .sim-sub-slogan {
        font-size: 14px; } }
  .sim-be-together-section .sim-fab {
    position: absolute;
    right: 8px;
    z-index: 3;
    background: #03B9E6 !important;
    color: white !important;
    line-height: 60px;
    bottom: 8px; }
  .sim-be-together-section .sim-btn-home {
    position: absolute;
    bottom: 127px;
    color: white;
    border-color: rgba(51, 204, 153, 0.01);
    background-color: #03B9E6;
    height: 90px;
    line-height: 90px;
    font-size: 20px;
    padding: 0px 20px;
    text-align: center;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0); }
    @media screen and (max-width: 480px) {
      .sim-be-together-section .sim-btn-home {
        display: none; } }

.sim-logo-image {
  height: 50px;
  width: 140px; }

.scrollspy .service_box_contanten {
  width: 100%;
  float: left; }

.scrollspy .services-box {
  width: 100%;
  float: left; }
  @media screen and (min-width: 500px) {
    .scrollspy .services-box {
      max-width: 33.333333%;
      margin-bottom: 40px; } }
  .scrollspy .services-box h5 {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 18px;
    font-weight: 300; }
  .scrollspy .services-box .mdl-button {
    margin-left: auto;
    margin-right: auto;
    display: table;
    width: auto;
    background: #fff; }
  .scrollspy .services-box p {
    widows: 100%;
    float: left;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center; }

.like_see {
  background: #F9FBFC;
  padding-bottom: 60px;
  width: 100%;
  padding-top: 3px; }
  .like_see.ternologias {
    display: table;
    background: #fff; }
    .like_see.ternologias .list_tec {
      float: left;
      width: 100%; }
      .like_see.ternologias .list_tec ul {
        padding: 0px 10%;
        list-style: outside none none;
        float: left;
        width: 100%; }
      .like_see.ternologias .list_tec li {
        float: left;
        width: 100%;
        min-height: 200px;
        padding: 1px; }
        @media screen and (min-width: 500px) {
          .like_see.ternologias .list_tec li {
            max-width: 25%; } }
        .like_see.ternologias .list_tec li figure {
          background: #F3F3F3;
          height: 200px;
          width: 100%;
          position: relative;
          margin: 0;
          padding: 0; }
        .like_see.ternologias .list_tec li svg {
          width: auto;
          height: 100px;
          width: 100px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.sevices-list {
  float: left;
  width: 100%;
  padding: 20px -webkit-calc(5% + 20px) 50px;
  padding: 20px calc(5% + 20px) 50px; }
  .sevices-list .services-box {
    float: left;
    width: 100%; }
    @media screen and (min-width: 500px) {
      .sevices-list .services-box {
        max-width: 50%; }
        .sevices-list .services-box .hi-icon-effect-1 {
          float: left;
          width: 200px; } }
    .sevices-list .services-box .hi-icon-effect-1 {
      float: left;
      width: 100%; }
      @media screen and (min-width: 500px) {
        .sevices-list .services-box .hi-icon-effect-1 {
          max-width: 200px; } }
    .sevices-list .services-box .divider {
      float: left;
      width: 100%; }
      @media screen and (min-width: 500px) {
        .sevices-list .services-box .divider {
          max-width: -webkit-calc(100% - 200px);
          max-width: calc(100% - 200px); } }

.section_procces {
  float: left;
  width: 100%; }
  .section_procces ol {
    margin: 0;
    text-align: center;
    counter-reset: section;
    float: left;
    width: 100%;
    padding: 0 5%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .section_procces ol li {
      float: left;
      margin: 0;
      width: 100%;
      margin-bottom: 40px;
      display: inline-block;
      padding: 0 22.5px;
      vertical-align: top;
      min-height: 410px;
      margin: 0 auto;
      position: relative;
      text-align: left; }
      @media screen and (min-width: 500px) {
        .section_procces ol li {
          max-width: 33.3333333%; } }
      .section_procces ol li h3 {
        font-size: 30px;
        line-height: 1.05263;
        margin-bottom: 32px; }
      .section_procces ol li p {
        font-size: 14px;
        line-height: 1.52941;
        margin-bottom: 20px; }
      .section_procces ol li:before {
        font-size: 220px;
        font-weight: 300;
        line-height: 1em;
        color: rgba(33, 150, 243, 0.45);
        counter-increment: section;
        content: counter(section);
        position: absolute;
        top: -23px;
        right: 0;
        z-index: -1;
        }

/* Effect 1 */
.hi-icon-effect-1 .hi-icon {
  display: block;
  font-size: 40px;
  margin: 35px auto;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 90px;
  text-align: center;
  position: relative;
  z-index: 1;
  -webkit-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
  color: #ffffff;
  background: #1d2527; /* Old browsers */
  background: -moz-linear-gradient(45deg, #1d2527 0%, #380762 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #1d2527 0%,#380762 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #1d2527 0%,#380762 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2527', endColorstr='#380762',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  .hi-icon-effect-1 .hi-icon::after {
    top: -7px;
    left: -7px;
    padding: 7px;
    -webkit-box-shadow: 0 0 0 4px #380762;
    box-shadow: 0 0 0 4px #380762;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    transition: transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    box-sizing: content-box; }
  .hi-icon-effect-1 .hi-icon:hover {
    color: #fff;
    }
    .hi-icon-effect-1 .hi-icon:hover:after {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1; }

.controls {
  width: 100%;
  display: table;
  padding-top: 10px;
  padding-bottom: 58px; }
  .controls .action {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px; }
    .controls .action .active {
      background: #03B9E6;
      color: #fff; }

.controls_container {
  padding-top: 95px;
  width: 100%;
  height: 989px;
  overflow: hidden;
  background: #eceff1;
  position: relative; }
  .controls_container::after {
    content: "";
    position: absolute;
    width: 130%;
    height: 200px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    left: 0;
    bottom: 0px; }

.container {
  width: 100%;
  max-width: 1145px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-backface-visibility: hidden;
  margin-bottom: 58px; }

.poster_imge {
  width: auto !important;
  height: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 100% !important;
  max-height: 100% !important; }

.carousels_container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 600px; }
  .carousels_container input[type="radio"] {
    display: none; }

.carousels_container {
  width: 100%;
  float: left;
  position: relative;
  -webkit-perspective: 100vw;
  perspective: 100vw;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  z-index: 1; }
  .carousels_container::before {
    content: "";
    position: absolute;
    -webkit-transform: rotateY(20deg);
    transform: rotateY(20deg);
    width: 130%;
    height: 400px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #eceff1;
    left: 0;
    top: -48px; }
  .carousels_container::after {
    content: "";
    position: absolute;
    -webkit-transform: rotateY(17deg);
    transform: rotateY(17deg);
    width: 130%;
    height: 250px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #eceff1;
    left: 0;
    bottom: 64px; }

.carousel_section {
  position: absolute;
  -webkit-perspective: 100vw;
  perspective: 100vw;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  width: 100%;
  left: 0px;
  top: 0px;
  -webkit-transition: all .5s;
  transition: all .5s;
  left: -100%;
  opacity: 0;
  z-index: 1;
  height: 598px; }
  .carousel_section .inside {
    width: 130%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    transform-origin: left center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    height: 100%; }
    @media screen and (min-width: 799px) {
      .carousel_section .inside {
        -webkit-transform: rotateY(20deg);
        transform: rotateY(20deg); } }
    @media screen and (max-width: 798px) and (min-width: 480px) {
      .carousel_section .inside {
        -webkit-transform: rotateY(10deg);
        transform: rotateY(10deg); } }
    .carousel_section .inside .fuga {
      position: absolute;
      display: table;
      width: 100%;
      -webkit-transition: all 1s cubic-bezier(0.63, 0.49, 0.77, 1.01);
      transition: all 1s cubic-bezier(0.63, 0.49, 0.77, 1.01);
      height: 100%; }
    .carousel_section .inside figure {
      display: table;
      margin: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      cursor: pointer; }
      @media screen and (min-width: 481px) {
        .carousel_section .inside figure {
          height: 596px; } }
      @media screen and (max-width: 480px) {
        .carousel_section .inside figure {
          height: 348px; } }
      .carousel_section .inside figure.web {
        padding-right: 20px;
        padding-left: 20px; }
        @media screen and (min-width: 799px) {
          .carousel_section .inside figure.web {
            width: 23%; } }
        @media screen and (max-width: 799px) {
          .carousel_section .inside figure.web {
            width: 100%; } }
        @media screen and (min-width: 481px) {
          .carousel_section .inside figure.web a, .carousel_section .inside figure.web span {
            max-width: 579px;
            min-height: 326px; }
            .carousel_section .inside figure.web a img, .carousel_section .inside figure.web span img {
              min-height: 326px; }
            .carousel_section .inside figure.web a .shine, .carousel_section .inside figure.web span .shine {
              max-height: 326px; } }
        @media screen and (max-width: 480px) {
          .carousel_section .inside figure.web a, .carousel_section .inside figure.web span {
            max-width: 480px; }
            .carousel_section .inside figure.web a img, .carousel_section .inside figure.web span img {
              max-width: 326px; } }
      @media screen and (min-width: 799px) {
        .carousel_section .inside figure.android, .carousel_section .inside figure.ios {
          width: 13%; } }
      @media screen and (max-width: 799px) {
        .carousel_section .inside figure.android, .carousel_section .inside figure.ios {
          width: 100%; } }
      .carousel_section .inside figure.android a, .carousel_section .inside figure.android span, .carousel_section .inside figure.ios a, .carousel_section .inside figure.ios span {
        max-width: 273px;
        min-height: 450px;
        width: 100%; }
        .carousel_section .inside figure.android a img, .carousel_section .inside figure.android span img, .carousel_section .inside figure.ios a img, .carousel_section .inside figure.ios span img {
          max-height: 450px; }
        .carousel_section .inside figure.android a .shine, .carousel_section .inside figure.android span .shine, .carousel_section .inside figure.ios a .shine, .carousel_section .inside figure.ios span .shine {
          max-height: 450px; }
      .carousel_section .inside figure .carousel-item-description {
        padding-right: 0;
        position: absolute;
        bottom: 0px;
        width: 100%; }
        .carousel_section .inside figure .carousel-item-description a, .carousel_section .inside figure .carousel-item-description span {
          cursor: pointer;
          text-decoration: none;
          -webkit-transition: all 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
          transition: all 100ms cubic-bezier(0.25, 0.1, 0.25, 1); }
        .carousel_section .inside figure .carousel-item-description .apps-carousel__item-description-icon-link {
          margin-bottom: 20px;
          display: inline-block;
          height: 65px;
          position: absolute;
          top: -97px; }
          @media screen and (min-width: 481px) {
            .carousel_section .inside figure .carousel-item-description .apps-carousel__item-description-icon-link {
              left: -webkit-calc(50% - 32px);
              left: calc(50% - 32px); } }
          @media screen and (max-width: 480px) {
            .carousel_section .inside figure .carousel-item-description .apps-carousel__item-description-icon-link {
              left: 27%; } }
          .carousel_section .inside figure .carousel-item-description .apps-carousel__item-description-icon-link .icom_cir {
            display: inline-block;
            width: 64px;
            height: 64px;
            max-height: 64px;
            min-height: 64px;
            display: block;
            -webkit-border-radius: 100%;
            border-radius: 100%;
            background: #e0e0e0;
            border: 1px solid #e0e0e0;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            margin-top: 20px; }
          .carousel_section .inside figure .carousel-item-description .apps-carousel__item-description-icon-link .icom_img {
            width: 64px;
            height: 64px;
            max-height: 64px;
            min-height: 64px;
            -webkit-border-radius: 3px;
            border-radius: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            margin-top: 20px;
            background: #fbfbfb;
            border: 1px solid #e0e0e0; }
        .carousel_section .inside figure .carousel-item-description .carousel-item-description-link {
          color: #263238;
          font-size: 16px;
          text-align: center; }
          @media screen and (max-width: 480px) {
            .carousel_section .inside figure .carousel-item-description .carousel-item-description-link {
              max-width: 320px; } }
      .carousel_section .inside figure span.poster,
      .carousel_section .inside figure a.poster {
        display: table;
        width: 100%;
        height: auto;
        margin: auto;
        position: relative; }
        @media screen and (min-width: 481px) {
          .carousel_section .inside figure span.poster,
          .carousel_section .inside figure a.poster {
            width: -webkit-calc(100% - 20px);
            width: calc(100% - 20px);
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            -webkit-box-shadow: -25px 50px 30px rgba(0, 0, 0, 0.125);
            box-shadow: -25px 50px 30px rgba(0, 0, 0, 0.125);
            z-index: 20; }
            .carousel_section .inside figure span.poster .shine,
            .carousel_section .inside figure a.poster .shine {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 100; }
            .carousel_section .inside figure span.poster img,
            .carousel_section .inside figure a.poster img {
              width: 100%;
              height: 100%;
              margin: auto;
              display: block; } }
      @media screen and (min-width: 481px) {
        .carousel_section .inside figure:nth-child(1) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(1- 1)));
          -ms-transform: translateX(calc(247px * calc(1- 1)));
          transform: translateX(calc(247px * calc(1- 1))); }
        .carousel_section .inside figure:nth-child(2) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(2- 1)));
          -ms-transform: translateX(calc(247px * calc(2- 1)));
          transform: translateX(calc(247px * calc(2- 1))); }
        .carousel_section .inside figure:nth-child(3) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(3- 1)));
          -ms-transform: translateX(calc(247px * calc(3- 1)));
          transform: translateX(calc(247px * calc(3- 1))); }
        .carousel_section .inside figure:nth-child(4) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(4- 1)));
          -ms-transform: translateX(calc(247px * calc(4- 1)));
          transform: translateX(calc(247px * calc(4- 1))); }
        .carousel_section .inside figure:nth-child(5) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(5- 1)));
          -ms-transform: translateX(calc(247px * calc(5- 1)));
          transform: translateX(calc(247px * calc(5- 1))); }
        .carousel_section .inside figure:nth-child(6) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(6- 1)));
          -ms-transform: translateX(calc(247px * calc(6- 1)));
          transform: translateX(calc(247px * calc(6- 1))); }
        .carousel_section .inside figure:nth-child(7) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(7- 1)));
          -ms-transform: translateX(calc(247px * calc(7- 1)));
          transform: translateX(calc(247px * calc(7- 1))); }
        .carousel_section .inside figure:nth-child(8) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(8- 1)));
          -ms-transform: translateX(calc(247px * calc(8- 1)));
          transform: translateX(calc(247px * calc(8- 1))); }
        .carousel_section .inside figure:nth-child(9) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(9- 1)));
          -ms-transform: translateX(calc(247px * calc(9- 1)));
          transform: translateX(calc(247px * calc(9- 1))); }
        .carousel_section .inside figure:nth-child(10) {
          -webkit-transform: translateX(-webkit-calc(247px * -webkit-calc(10- 1)));
          -ms-transform: translateX(calc(247px * calc(10- 1)));
          transform: translateX(calc(247px * calc(10- 1))); } }

.carousel_btn {
  border: none;
  width: 64px;
  font-size: 32px;
  height: 64px;
  position: absolute;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background: #FFF;
  outline: 0;
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
  top: 32%;
  cursor: pointer; }
  @media screen and (max-width: 480px) {
    .carousel_btn {
      top: 201px; } }
  .carousel_btn:hover {
    background: #F2F1F0; }
  .carousel_btn.btn-left {
    left: 25px; }
  .carousel_btn.btn-right {
    right: 25px; }

#all:checked ~ #c-index-1,
#Android:checked ~ #c-index-2,
#IOS:checked ~ #c-index-3,
#Web:checked ~ #c-index-4 {
  left: 0%;
  opacity: 1; }

.title {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 64px;
  font-weight: 300;
  margin-top: 40px;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 50px;
  text-transform: uppercase;
  line-height: 110%;
  letter-spacing: 5px; }
  @media screen and (min-width: 481px) {
    .title {
      font-size: 64px; } }
  @media screen and (max-width: 480px) {
    .title {
      font-size: 30px; } }
  .title::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 100px;
    height: 5px;
    margin-left: -50px;
    margin-top: 35px;
    background: #03B9E6; }

.text {
  display: block;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; }
  .text p {
    font-size: 20px;
    line-height: 1.5em;
    text-align: center;
    font-weight: 300; }
  .text .mdl-button {
    margin-left: auto;
    margin-right: auto;
    display: table;
    color: white !important; }

.wp2 {
  opacity: 0; }

.btn_center {
  margin: 0 auto;
  display: table;
  background: #03B9E6;
  color: #fff; }
  .btn_center:hover {
    background: #84DCA3; }

.hidden {
  display: none; }

.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1em;
  width: -webkit-calc(100% - 2em);
  width: calc(100% - 2em);
  vertical-align: top; }

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: 400; }
  .input__field:focus {
    outline: none; }
  .input__field:invalid {
    -webkit-box-shadow: none;
    box-shadow: none; }
  .input__field:valid ~ .graphic {
    stroke: #03B9E6 !important; }

.input__field--nao {
  padding: 0.5em 0em 0.25em;
  width: 100%;
  background: transparent;
  color: #363636;
  font-size: 1.25em;
  resize: none; }

.input__label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #696969;
  font-weight: bold; }

.input__label-content {
  position: relative;
  display: block;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%; }

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none; }

.icon {
  color: #ddd;
  font-size: 150%; }

/* Nao */
.input--nao {
  overflow: hidden;
  padding-top: 1em; }

.input__label--nao {
  position: absolute;
  top: 0.95em;
  font-size: 0.85em;
  left: 0;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0em;
  pointer-events: none;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
  transition: transform 0.2s 0.15s, color 1s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transform-origin: 0 0;
  -webkit-transition: -webkit-transform 0.2s 0.15s, color 1s;
  -webkit-transition-timing-function: ease-out; }

.graphic--nao {
  stroke: #92989e;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.7s, stroke 0.7s;
  transition: transform 0.7s, stroke 0.7s;
  -webkit-transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1); }

.input__field--nao:focus + .input__label--nao,
.input--filled .input__label--nao {
  color: #333;
  -webkit-transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
  transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1); }

.input__field--nao:focus ~ .graphic--nao,
.input--filled .graphic--nao {
  stroke: #333;
  -webkit-transform: translate3d(-66.6%, 0, 0);
  transform: translate3d(-66.6%, 0, 0); }

.contact-cap {
  margin: 20px auto;
  display: block;
  width: 300px; }

.capcha {
  background: #ff4d4d;
  padding: 20px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: none;
  font-size: 16px;
  font-weight: 100;
  color: #FFF;
  right: 0px;
  bottom: 70px;
  position: absolute; }

.section_studio {
  width: 100%;
  height: 600px;
  overflow: hidden;
  position: relative;
  background: #1d2527 none repeat scroll 0% 0%; }
  .section_studio img {
    opacity: 0.3; }
  .section_studio .wp1 {
    position: absolute;
    top: 0;
    width: 100%; }
    .section_studio .wp1 h2, .section_studio .wp1 p {
      color: #fff; }
  .section_studio .container {
    margin-bottom: 0px; }
    .section_studio .container .title {
      margin-bottom: 20px;
      margin-top: 200px; }

.grid {
  width: 100%;
  list-style: none;
  text-align: center;
  display: table;
  float: left;
  padding: 0px; }
  .grid li {
    width: 50%;
    margin: 0;
    padding: 8px;
    text-align: left;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left; }
    .grid li:hover figure figcaption {
      opacity: 1; }
    .grid li:hover figure .cap {
      -webkit-transform: scale3d(50, 50, 50);
      -ms-transform: scale3d(50, 50, 50);
      transform: scale3d(50, 50, 50); }
  .grid figure {
    margin: 0;
    position: relative;
    overflow: hidden;
    padding: 10px; }
    .grid figure img {
      width: 100%;
      display: block;
      position: relative; }
  .grid .cap {
    will-change: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transform: scale3d(0, 0, 0);
    -ms-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    background: rgba(109, 176, 3, 0.69); }
  .grid figcaption {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    overflow: hidden;
    color: #36365A;
    height: 100%;
    width: 100%;
    opacity: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.3s, opacity 0.3s;
    transition: all 0.3s, opacity 0.3s; }
    .grid figcaption h3 {
      margin: 0;
      padding: 0;
      color: #fff;
      margin-top: 70px; }
    .grid figcaption span:before {
      content: 'by ';
      display: block; }
    .grid figcaption a {
      text-align: center;
      padding: 5px 10px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      display: inline-block;
      background: #03B9E6;
      color: #fff;
      margin-top: 30px; }

.validate {
  margin-left: auto;
  margin-right: auto;
  display: table;
  width: 100%;
  max-width: 900px;
  margin-top: 50px;
  position: relative; }
  .validate .mdl-button {
    margin-left: auto;
    margin-right: auto;
    display: table;
    width: auto;
    color: #fff !important; }

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  border-bottom: 3px solid #E1E1E1; }
  .owl-carousel.nonloop {
    float: left; }
  .owl-carousel .animated {
    -webkit-animation-duration: 1000ms;
    animation-duration: 1000ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .owl-carousel .owl-animated-in {
    z-index: 0; }
  .owl-carousel .owl-animated-out {
    z-index: 1; }
  .owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y; }
    .owl-carousel .owl-stage:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-prev,
  .owl-carousel .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-prev,
  .owl-carousel .owl-next {
    position: absolute;
    width: 48px;
    height: 48px;
    top: 40%;
    background: #fff;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    font-size: 2em;
    color: #363636;
    -webkit-transition: opacity 500ms ease-in-out;
    transition: opacity 500ms ease-in-out; }
    .owl-carousel .owl-prev i,
    .owl-carousel .owl-next i {
      line-height: 48px; }
  .owl-carousel .owl-prev {
    left: 10px; }
  .owl-carousel .owl-next {
    right: 10px; }
  .owl-carousel .item {
    height: 100%;
    width: 100%;
    padding-right: 16px;
    border-right: 1px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    position: relative;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
    .owl-carousel .item:hover {
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%); }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel .owl-controls {
    width: 100%; }
  .owl-carousel .owl-nav {
    width: 100%;
    height: 100%; }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 354px; }
    .owl-carousel .owl-item img {
      display: block;
      width: 100%;
      -webkit-transform-style: preserve-3d; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out; }

.owl-carousel .owl-refresh .owl-item {
  display: none; }

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; }

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/* 
*   Owl Carousel - Lazy Load Plugin
*/
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease; }

.tilte_owl {
  text-align: center;
  padding: 0px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
  color: #575151; }

@font-face {
  font-family: 'CMS-SIM';
  src:  url('fonts/CMS-SIM.eot?10miuu');
  src:  url('fonts/CMS-SIM.eot?10miuu#iefix') format('embedded-opentype'),
    url('fonts/CMS-SIM.ttf?10miuu') format('truetype'),
    url('fonts/CMS-SIM.woff?10miuu') format('woff'),
    url('fonts/CMS-SIM.svg?10miuu#CMS-SIM') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon_"], [class*=" icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CMS-SIM' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_tablet:before {
  content: "\e900";
}
.icon_google:before {
  content: "\e901";
}
.icon_idea:before {
  content: "\e902";
}
.icon_user-refer:before {
  content: "\e903";
}
.icon_server:before {
  content: "\e022";
}
.icon_power:before {
  content: "\e086";
}
.icon_arrow_back:before {
  content: "\e600";
}
.icon_arrow_forward:before {
  content: "\e601";
}
.icon_check:before {
  content: "\e602";
}
.icon_chevron_left:before {
  content: "\e603";
}
.icon_chevron_right:before {
  content: "\e604";
}
.icon_close:before {
  content: "\e605";
}
.icon_expand_less:before {
  content: "\e606";
}
.icon_expand_more:before {
  content: "\e607";
}
.icon_menu:before {
  content: "\e608";
}
.icon_cached:before {
  content: "\e609";
}
.icon_more_vert:before {
  content: "\e60a";
}
.icon_refresh:before {
  content: "\e60b";
}
.icon_check_circle:before {
  content: "\e60c";
}
.icon_done:before {
  content: "\e60d";
}
.icon_done_all:before {
  content: "\e60e";
}
.icon_help:before {
  content: "\e60f";
}
.icon_schedule:before {
  content: "\e610";
}
.icon_search:before {
  content: "\e611";
}
.icon_settings:before {
  content: "\e612";
}
.icon_trending_neutral:before {
  content: "\e613";
}
.icon_trending_up:before {
  content: "\e614";
}
.icon_attach_filex:before {
  content: "\e615";
}
.icon_border_color:before {
  content: "\e616";
}
.icon_insert_comment:before {
  content: "\e617";
}
.icon_insert_drive_file:before {
  content: "\e618";
}
.icon_mode_edit:before {
  content: "\e619";
}
.icon_phone_iphone:before {
  content: "\e61a";
}
.icon_backspace:before {
  content: "\e61b";
}
.icon_block:before {
  content: "\e61c";
}
.icon_clear:before {
  content: "\e61d";
}
.icon_content_copy:before {
  content: "\e61e";
}
.icon_content_paste:before {
  content: "\e61f";
}
.icon_create:before {
  content: "\e620";
}
.icon_markunread:before {
  content: "\e621";
}
.icon_reply:before {
  content: "\e622";
}
.icon_save:before {
  content: "\e623";
}
.icon_sim_card:before {
  content: "\e624";
}
.icon_attachment:before {
  content: "\e625";
}
.icon_cloud:before {
  content: "\e626";
}
.icon_cloud_done:before {
  content: "\e627";
}
.icon_inbox:before {
  content: "\e629";
}
.icon_mas:before {
  content: "\e62a";
}
.icon_ic_send:before {
  content: "\e62b";
}
.icon_ic_delete:before {
  content: "\e62c";
}
.icon_editable:before {
  content: "\e62d";
}
.icon_editable_08:before {
  content: "\e62f";
}
.icon_grade:before {
  content: "\e630";
}
.icon_info_outline:before {
  content: "\e631";
}
.icon_language:before {
  content: "\e632";
}
.icon_open_in_new:before {
  content: "\e633";
}
.icon_perm_device_info:before {
  content: "\e634";
}
.icon_perm_identity:before {
  content: "\e635";
}
.icon_perm_phone_msg:before {
  content: "\e636";
}
.icon_room:before {
  content: "\e637";
}
.icon_track_changes:before {
  content: "\e638";
}
.icon_favorite_outline:before {
  content: "\e640";
}
.icon_cloud_upload:before {
  content: "\e641";
}
.icon_ic_done:before {
  content: "\e642";
}
.icon_ic_done_all:before {
  content: "\e643";
}
.icon_favorite:before {
  content: "\e644";
}
.icon_image:before {
  content: "\e645";
}
.icon_shopping_cart:before {
  content: "\e646";
}
.icon_movie_creation:before {
  content: "\e647";
}
.icon_remove_red_eye:before {
  content: "\e648";
}
.icon_report:before {
  content: "\e649";
}
.icon_backup:before {
  content: "\e64a";
}
.icon_loyalty:before {
  content: "\e64b";
}
.icon_bookmark:before {
  content: "\e64c";
}
.icon_web_3:before {
  content: "\e904";
}
.icon_diseno_y_estrategia_de_Producto:before {
  content: "\e905";
}
.icon_estrategia_mobile:before {
  content: "\e906";
}
.icon_computer:before {
  content: "\e907";
}
.icon_desktop_mac:before {
  content: "\e908";
}
.icon_ic_keyboard:before {
  content: "\e909";
}
.icon_memory:before {
  content: "\e90a";
}
.icon_ic_phone_iphone:before {
  content: "\e90b";
}
.icon_phonelink:before {
  content: "\e90c";
}
.icon_facebook:before {
  content: "\e90d";
}
.icon_instagram:before {
  content: "\e90e";
}
.icon_Mejoras_y_nuevas_funcionalidades:before {
  content: "\e90f";
}
.icon_producto:before {
  content: "\e910";
}
.icon_estrategias_tecnicas:before {
  content: "\e911";
}
.icon_Mantenimiento_preventivo:before {
  content: "\e912";
}
.icon_Apple_logo_black:before {
  content: "\e913";
}
.icon_Android_robot:before {
  content: "\e914";
}
.icon_performance_optimizations:before {
  content: "\e915";
}
.icon_tacometro:before {
  content: "\e916";
}
.icon_prueba:before {
  content: "\e917";
}
.icon_seachr_lab:before {
  content: "\e918";
}
.icon_responsive:before {
  content: "\e919";
}
.icon_web_2:before {
  content: "\e91a";
}
.icon_web:before {
  content: "\e91b";
}
.icon_diamon:before {
  content: "\e91c";
}
.icon_noun_88031_cc:before {
  content: "\e91d";
}
.icon_noun_20721_cc:before {
  content: "\e91e";
}
.icon_quotes_left:before {
  content: "\e977";
}
.icon_quotes_right:before {
  content: "\e978";
}
.icon_share2:before {
  content: "\ea82";
}
.icon_google-plus:before {
  content: "\ea88";
}
.icon_twitter:before {
  content: "\ea91";
}
.icon_dribbble:before {
  content: "\eaa6";
}
.icon_linkedin2:before {
  content: "\eac9";
}
.icon_pinterest:before {
  content: "\ead0";
}


.section_page {
  float: left;
  width: 100%;
  position: relative; }
  .section_page .sub_background_content {
    float: left;
    width: 100%;
    height: 600px;
    overflow: hidden;
    top: 0px;
    position: relative;
    -webkit-transition: all 2s;
    transition: all 2s;
    background: #323a45 none repeat scroll 0% 0%; }
    .section_page .sub_background_content img {
      opacity: 0.2; }
    .section_page .sub_background_content h1 {
      position: absolute;
      bottom: 300px;
      width: 100%;
      text-align: center;
      color: #fafafa;
      padding-left: 20%;
      padding-right: 20%; }
      @media screen and (min-width: 500px) {
        .section_page .sub_background_content h1 {
          font-size: 56px; } }
      @media screen and (max-width: 499px) {
        .section_page .sub_background_content h1 {
          font-size: 30px; } }
    .section_page .sub_background_content .text_descriction {
      position: absolute;
      bottom: 0px;
      width: 100%;
      color: #f0f1f2;
      line-height: 32px;
      margin-top: 20px;
      font-weight: 300;
      text-align: center;
      padding-left: 20%;
      padding-right: 20%;
      height: 300px; }
      @media screen and (min-width: 500px) {
        .section_page .sub_background_content .text_descriction {
          font-size: 30px; } }
      @media screen and (max-width: 499px) {
        .section_page .sub_background_content .text_descriction {
          font-size: 18px; } }
    .section_page .sub_background_content .descriction {
      position: absolute;
      width: 100%;
      max-width: 300px;
      bottom: 0px;
      left: 5%;
      padding-bottom: 20px; }
      .section_page .sub_background_content .descriction span {
        float: left;
        width: 100%;
        padding-left: 20px;
        color: #cad7e3;
        font-weight: 300;
        font-size: 20px;
        line-height: 32px; }
  .section_page .content_page {
    width: 100%;
    top: 0px;
    padding-top: 200px; }
    .section_page .content_page .content_text {
      width: 100%;
      max-width: 90%;
      display: block;
      margin-left: auto;
      margin-right: auto; }
      .section_page .content_page .content_text .descriction {
        float: right;
        width: 100%;
        max-width: 300px;
        padding-top: 67px; }
        .section_page .content_page .content_text .descriction span {
          float: left;
          width: 100%;
          padding-left: 20px;
          color: #5e7283;
          font-weight: 300;
          font-size: 20px;
          line-height: 32px; }
      .section_page .content_page .content_text .text_descriction {
        float: left;
        width: 100%;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px; }
        .section_page .content_page .content_text .text_descriction.services {
          max-width: 100%;
          text-align: center;
          padding-left: 10%;
          padding-right: 10%; }
          .section_page .content_page .content_text .text_descriction.services p {
            text-align: center; }
        .section_page .content_page .content_text .text_descriction .title_page {
          color: #232732;
          line-height: 28px;
          margin-top: 40px;
          font-size: 20px;
          font-weight: 300;
          text-transform: uppercase; }
        .section_page .content_page .content_text .text_descriction p {
          color: #5e7283;
          line-height: 32px;
          margin-top: 22px;
          font-size: 20px;
          font-weight: 300;
          text-align: justify; }
.sim-header svg path.color {
  fill: #fff;}
.sticky-header .sim-header {
  background-color: #fff;
  border-bottom: 1px solid #eee; }
.sticky-header .sim-header svg path.color {
  fill: #000;}
  .sticky-header .sim-header .sim-logo samp {
    color: black; }
  .sticky-header .sim-header .sim-navigation a {
    color: #03B9E6; }
  @media screen and (max-width: 799px) {
    .sticky-header .sim-header .sim-navigation {
      background: white none repeat scroll 0% 0%; } }
  .sticky-header .sim-header .hamburger {
    background: #363636; }

.sticky-header .sub_background_content img {
  opacity: 0; }

.mt-100 {
  margin-top: 100px; }

.stepbox {
  margin: 0 auto 100px;
  display: table;
  width: 100%;
  max-width: 900px;
  position: relative; }
  .stepbox span {
    padding-top: 50px; }
    .stepbox span:not(.active) {
      -webkit-filter: grayscale(1);
      filter: grayscale(1); }
      .stepbox span:not(.active) svg {
        opacity: .2; }
      .stepbox span:not(.active) label {
        color: #D1D6D6;
        pointer-events: none; }
  .stepbox .input {
    margin: 1em 1em .3em 1em; }
  .stepbox .microtext {
    opacity: .4;
    margin: 0 1.1em;
    width: 100%; }
  .stepbox .relative_label {
    padding: 0; }
    .stepbox .relative_label label {
      position: relative; }
  .stepbox input[type="checkbox"],
  .stepbox input[type="radio"] {
    position: absolute;
    top: 90px;
    display: inline !important;
    opacity: 0; }

.sim_radio_btn label,
.sim_check_btn label {
  width: 120px;
  height: 120px;
  display: inline-table;
  border: 2px solid #D1D6D6;
  margin-top: 15px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  padding: 25px 10px 10px;
  -webkit-transition: .5s linear;
  transition: .5s linear;
  color: #03B9E6;
  background: white;
  font-size: 12px; }
  .sim_radio_btn label:hover,
  .sim_check_btn label:hover {
    -webkit-box-shadow: 1px 1px 3px #84DCA3;
    box-shadow: 1px 1px 3px #84DCA3;
    border: 1px solid #84DCA3;
    -webkit-transition: .2s linear;
    transition: .2s linear;
    cursor: pointer; }
  .sim_radio_btn label i,
  .sim_check_btn label i {
    display: block;
    font-size: 36px;
    margin-bottom: 10px; }

.sim_radio_btn label:before {
  -webkit-border-radius: 100%;
  border-radius: 100%; }

.sim_radio,
.sim_check, .sim_check_range {
  display: none; }
  .sim_radio:checked + label,
  .sim_check:checked + label, .sim_check_range:checked + label {
    background: #03B9E6;
    color: white;
    -webkit-box-shadow: 1px 1px 3px #84DCA3;
    box-shadow: 1px 1px 3px #84DCA3;
    border: 1px solid #84DCA3; }

.sim_check_range {
  display: block !important;
  width: 400px !important;
  height: 40px !important;
  line-height: 0;
  text-align: left !important; }

.cotize_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: table;
  height: 60px;
  width: 100%;
  background: white;
  border-top: 1px solid #D1D6D6;
  z-index: 2; }
  .cotize_footer .cont {
    max-width: 900px;
    height: 60px;
    margin: 0 auto;
    display: table; }
    @media screen and (min-width: 601px) {
      .cotize_footer .cont {
        width: 900px; } }
    @media screen and (max-width: 600px) {
      .cotize_footer .cont {
        width: 100%;
        padding: 0 10px; } }
  .cotize_footer .completed {
    color: #03B9E6;
    font-weight: 700; }
  .cotize_footer .percent {
    width: 200px;
    height: 7px;
    background: rgba(33, 150, 243, 0.38);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    margin: 0 10px; }
    .cotize_footer .percent span {
      display: inline-block;
      background: #03B9E6;
      float: left;
      height: 100%;
      -webkit-border-radius: 5px;
      border-radius: 5px; }
  .cotize_footer .table_cell {
    display: table-cell;
    vertical-align: middle; }

.square-selection {
  width: 120px;
  height: 120px;
  display: inline-table;
  border: 2px solid #D1D6D6;
  margin-top: 15px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  padding: 25px 10px 10px;
  -webkit-transition: .5s linear;
  transition: .5s linear;
  color: #03B9E6;
  font-size: 12px; }

.cotize_up, .cotize_down {
  width: 25px;
  height: 25px;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  cursor: pointer;
  background: #03B9E6;
  color: white;
  -webkit-box-shadow: 1px 1px 2px #D1D6D6;
  box-shadow: 1px 1px 2px #D1D6D6;
  border: none;
  padding: 0;
  font-size: 18px;
  line-height: 10px; }

.btn_cotize {
  background-color: #03B9E6;
  color: white;
  margin: 30px auto 0;
  display: block; }

.cotize-dropzone {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 2px dashed #03B9E6 !important;
  color: #03B9E6;
  -webkit-border-image: none;
  border-image: none;
  max-width: 800px;
  margin-right: auto;
  margin-top: 45px;
  height: 200px; }
  .cotize-dropzone .dz-message span {
    padding-top: 15px;
    display: inline-block; }

.cotiza-home small {
  display: block;
  color: #03B9E6;
  opacity: 1; }

.cotiza-home a {
  color: white !important;
  display: block;
  max-width: 150px;
  margin: 30px auto; }

div.input.input--nao {
  margin: 0; }
  div.input.input--nao label {
    top: 0; }
  div.input.input--nao input {
    position: absolute;
    bottom: 0; }

.is-hidden {
  display: none; }

.btn.btn-link-force {
  color: #0a6ebd !important; }

.text-dark {
  color: #343434 !important; }

.no-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.p-10 {
  padding: 10px; }

.sim_footer {
  width: 100%;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #1d2527; /* Old browsers */
  background: -moz-linear-gradient(45deg, #1d2527 0%, #380762 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #1d2527 0%,#380762 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #1d2527 0%,#380762 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2527', endColorstr='#380762',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }
  .sim_footer #particles-footer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 350px; }
  .sim_footer .S_F_content {
    padding-bottom: 60px;
    width: 100%;
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    @media screen and (min-width: 1183px) {
      .sim_footer .S_F_content {
        padding-left: 210px;
        padding-right: 210px; } }
    @media screen and (min-width: 956px) and (max-width: 1183px) {
      .sim_footer .S_F_content {
        padding-left: 150px;
        padding-right: 150px; } }
    @media screen and (min-width: 845px) and (max-width: 955px) {
      .sim_footer .S_F_content {
        padding-left: 100px;
        padding-right: 100px; } }
    @media screen and (max-width: 844px) {
      .sim_footer .S_F_content {
        padding-right: 10px;
        padding-left: 10px; } }
  .sim_footer .s_f_content_text {
    padding-bottom: 71px;
    padding-top: 100px;
    position: relative; }
    .sim_footer .s_f_content_text .s_f_title {
      color: #fff;
      letter-spacing: -1.6px;
      margin-top: 40px !important;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
      z-index: 1;
      font-weight: 300;
      position: relative;
      display: table;
      text-align: center;
      }
      @media screen and (min-width: 480px) {
        .sim_footer .s_f_content_text .s_f_title {
          line-height: 70px;
          font-size: 48px; } }
      @media screen and (max-width: 479px) {
        .sim_footer .s_f_content_text .s_f_title {
          font-size: 22px;
          line-height: 40px; } }
    .sim_footer .s_f_content_text .s_f_text {
      width: 100%;
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      color: #ffffff;
      margin-top: 12px !important;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
      font-weight: 300;
      position: relative;
      display: table;
      text-align: center;
      }
      @media screen and (min-width: 845px) {
        .sim_footer .s_f_content_text .s_f_text {
          max-width: 770px; } }
      @media screen and (min-width: 480px) {
        .sim_footer .s_f_content_text .s_f_text {
          font-size: 28px;
          line-height: 42px; } }
      @media screen and (max-width: 479px) {
        .sim_footer .s_f_content_text .s_f_text {
          font-size: 16px;
          line-height: 30px; } }
  .sim_footer .s_f_list_social_Links {
    margin: 0px !important;
    width: 100%;
    float: left; }
    .sim_footer .s_f_list_social_Links a {
      border-width: 1px;
      border-style: solid;
      border-color: #a9b5bf;
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
      float: left;
      position: relative;
      min-height: 1px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0px !important;
      height: 80px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      text-decoration: none;
      -webkit-box-sizing: border-box;
      box-sizing: border-box; }
      @media screen and (min-width: 631px) {
        .sim_footer .s_f_list_social_Links a {
          width: 25%;
          border-right: none; }
          .sim_footer .s_f_list_social_Links a:first-of-type {
            -webkit-border-radius: 100px 0px 0px 100px;
            border-radius: 100px 0px 0px 100px; }
          .sim_footer .s_f_list_social_Links a:last-of-type {
            -webkit-border-radius: 0px 100px 100px 0px;
            border-radius: 0px 100px 100px 0px;
            border-right: 1px solid #a9b5bf; } }
      @media screen and (max-width: 630px) {
        .sim_footer .s_f_list_social_Links a {
          width: 100%;
          -webkit-border-radius: 100px;
          border-radius: 100px;
          margin-bottom: 16px; } }
      .sim_footer .s_f_list_social_Links a:hover * {
        color: white !important; }
      .sim_footer .s_f_list_social_Links a:hover.facebook {
        background: #4769bb;
        border-color: #4769bb; }
      .sim_footer .s_f_list_social_Links a:hover.twitter {
        background: #55acee;
        border-color: #55acee; }
      .sim_footer .s_f_list_social_Links a:hover.google {
        background: #f04b48;
        border-color: #f04b48; }
      .sim_footer .s_f_list_social_Links a:hover.vk {
        background: #6691c7;
        border-color: #6691c7; }
      .sim_footer .s_f_list_social_Links a:hover.pinterest {
        background: #ca212a;
        border-color: #ca212a; }
      .sim_footer .s_f_list_social_Links a svg {
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        color: #a9b5bf;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-left: 30px !important;
        height: 25px;
        width: 30px; }
      .sim_footer .s_f_list_social_Links a .name {
        color: #a9b5bf;
        margin: 0 auto;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        font-size: 18px;
        text-align: center; }
      .sim_footer .s_f_list_social_Links a .num {
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        color: #a9b5bf;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-right: 41px !important;
        font-size: 12px; }
  .sim_footer .s_f_menu_links {
    margin-top: 73px;
    text-align: center;
    width: 100%;
    float: left; }
    .sim_footer .s_f_menu_links a {
      color: #f4f4f4;
      font-size: 15px;
      text-decoration: none;
      width: 100%;
      /*float: left;*/
      }
      @media screen and (min-width: 480px) {
        .sim_footer .s_f_menu_links a {
          max-width: 33.333333%; } }
      @media screen and (max-width: 480px) {
        .sim_footer .s_f_menu_links a {
          margin-bottom: 8px;
          line-height: 36px; } }
  .sim_footer .s_f_copi {
    color: #a9b5bf;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-top: 28px;
    font-size: 12px;
    text-align: center;
    float: left;
    width: 100%; }

.social2 {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center; }
  .social2 .social_li {
    display: inline-block;
    border: 1px solid #a9b5bf;
    height: 77px;
    width: 25%;
    color: #a9b5bf;
    font-size: 16px; }
    .social2 .social_li.facebook:hover {
      background: #4769bb; }
    .social2 .social_li.twitter:hover {
      background: #55acee; }
    .social2 .social_li.instagram:hover {
      background: #E91E63; }
    .social2 .social_li:hover a {
      color: #fff !important; }
    .social2 .social_li:first-of-type {
      -webkit-border-top-left-radius: 60px;
      border-top-left-radius: 60px;
      -webkit-border-bottom-left-radius: 60px;
      border-bottom-left-radius: 60px;
      border-right: none; }
    .social2 .social_li:last-of-type {
      -webkit-border-top-right-radius: 60px;
      border-top-right-radius: 60px;
      -webkit-border-bottom-right-radius: 60px;
      border-bottom-right-radius: 60px;
      border-left: none; }
    .social2 .social_li a {
      color: #a9b5bf;
      display: inline-block;
      width: 100%;
      height: 100%;
      position: relative; }
      @media screen and (min-width: 631px) {
        .social2 .social_li a {
          line-height: 4.5; } }
      @media screen and (max-width: 630px) {
        .social2 .social_li a {
          padding-top: 26px; } }
    .social2 .social_li .icons {
      font-size: 24px; }
      @media screen and (min-width: 631px) {
        .social2 .social_li .icons {
          position: absolute;
          left: 10%;
          top: -webkit-calc( 50% - 12px);
          top: calc( 50% - 12px); } }
    @media screen and (max-width: 630px) {
      .social2 .social_li .mobile_foo {
        display: none; } }

/*# sourceMappingURL=web_main.css.map */

.mt-100 {
  margin-top: 100px;
}

.stepbox {
  margin: 0 auto 100px;
  display: table;
  width: 100%;
  max-width: 900px;
  position: relative;
  span {
    padding-top: 50px;
    &:not(.active) {
      filter: grayscale(1);
      svg{
        opacity: .2;
      }
      label, input {
        color: $color3;
        //pointer-events: none;
      }

      input[type="text"], input[type="email"], input[type="url"] {
        border-bottom: 2px solid #97e39d;
      }
    }

    input[type="text"], input[type="email"], input[type="url"] {
      border-bottom: 2px solid #2196F3;
    }
  }

  .input {
    margin: 1em 1em .3em 1em;
  }

  .microtext {
    opacity: .4;
    margin: 0 1.1em;
    width: 100%;
  }

  .relative_label {
    padding: 0;

    label {
      position:relative;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    top: 90px;
    display: inline !important;
    opacity: 0;
  }
}

.sim_radio_btn label,
.sim_check_btn label{
  width: 120px;
  height: 120px;
  display: inline-table;
  border: 2px solid $color3;
  margin-top: 15px;
  border-radius: 4px;
  text-align: center;
  padding: 25px 10px 10px;
  transition: .5s linear;
  color: $verde_sim;
  background: white;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 1px 3px $color6;
    border: 1px solid $color6;
    transition: .2s linear;
    cursor: pointer;
  }
  i {
    display: block;
    font-size: 36px;
    margin-bottom: 10px;
  }
}

.sim_radio_btn label{
  &:before {
    border-radius: 100%;
  }
}

.sim_radio,
.sim_check, .sim_check_range {
  display: none;
  &:checked + label {
    background: $verde_sim;
    color: white;
    box-shadow: 1px 1px 3px $color6;
    border: 1px solid $color6;
  }
}

.sim_check_range {
  display: block !important;
  width: 400px !important;
  height: 40px !important;
  line-height: 0;
  text-align: left !important;
}


.cotize_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: table;
  height: 60px;
  width: 100%;
  background: white;
  border-top: 1px solid $color3;
  z-index: 2;
  .cont{
    max-width:900px;
    height: 60px;
    margin: 0 auto;
    display: table;
    @media screen and (min-width: 601px) {
      width: 900px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 0 10px;
    }
  }

  .completed{
    color:$verde_sim;
    font-weight: 700;
  }

  .percent{
    width: 200px;
    height: 7px;
    background: rgba(109, 176, 3, .5);
    border-radius: 5px;
    display: inline-block;
    margin: 0 10px;
    span{
      display: inline-block;
      background: $verde_sim;
      float: left;
      height: 100%;
      border-radius: 5px;
    }
  }
  .table_cell{
    display: table-cell;
    vertical-align: middle;
  }
}

.square-selection {
  width: 120px;
  height: 120px;
  display: inline-table;
  border: 2px solid $color3;
  margin-top: 15px;
  border-radius: 4px;
  text-align: center;
  padding: 25px 10px 10px;
  transition: .5s linear;
  color: $verde_sim;
  font-size: 12px;

}

.cotize_up, .cotize_down {
  width: 25px;
  height: 25px;
  border-radius:100%;
  cursor: pointer;
  background: $verde_sim;
  color: white ;
  box-shadow: 1px 1px 2px $color3;
  border: none;
  padding: 0;
  font-size: 18px;
  line-height: 10px;
}

.btn_cotize{
  background-color: $verde_sim;
  color: white;
  margin: 30px auto 0;
  display: block;
}

.cotize-dropzone {
  border-radius: 5px;
  border: 2px dashed $verde_sim !important;
  color:$verde_sim;
  border-image: none;
  max-width: 800px;
  margin-right: auto;
  margin-top: 45px;
  height: 200px;
  .dz-message {
    span{
      padding-top: 15px;
      display: inline-block;
    }
  }
}

.cotiza-home{
  small{
    display: block;
    color: $verde_sim;
    opacity: 1;
  }
  a{
    color: white !important;
    display: block;
    max-width: 150px;
    margin: 30px auto;
  }
}

div.input.input--nao {
  margin: 0;
  label {
    top: 0;
  }

  input {
    position: absolute;
    bottom: 0;
  }
}

.is-hidden {
  display: none;
}

.btn.btn-link-force {
  color: #0a6ebd !important;
}

.text-dark {
  color: #343434 !important;
}

.text-danger {
  color: #f6675d;
}

.text-white {
  color: #ffffff !important;
}

.bg-gray {
  background: rgb(233, 239, 242);
}

.no-shadow {
  box-shadow: none !important;
}

.p-10 {
  padding: 10px;
}
.palette-Green-800 {
  background-color: #03B9E6;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.statistics {
  //background: rgb(233, 239, 242);

  .statistics-header {
    max-height: 300px;

    h3 {
      margin-top: 0;
    }

  }

  .statistics-row {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 5px;

    .statistic {
      flex-grow: 1;
      flex-basis: 0;
      border-right: 2px solid #8c8c8c;
      //margin-right: 10px;
      padding: 10px;

      h2 {
        margin-top: -3px;
      }

      &:last-child {
        border: none;
      }
    }

    &.no-separator .statistic {
      border: none;
    }

    .progress {
      height: 20px;
      border-radius: 20px;

      &.ios {
        background: $ios-dark;

        .progress-bar {
          background: $ios-light;
          color: $ios-dark;
          border: 1px solid $ios-dark;
        }
      }

      &.android {
        .progress-bar {
          background: $android;
          border: 1px solid $ios-light;
        }
      }

      .progress-bar {
        width: 100%;
        border-radius: 20px;
        border: 1px solid $ios-light;
        font-weight: bold;
      }
    }
  }
}

.input__field--nao:focus + .input__label--nao, .input--filled .input__label--nao {
  transform: translateY(-25px);
}


.badge {
  border-radius: 200px !important;

  &-red {
    background: #eb3723 !important;
  }

  &-amber {
    background: #ffd858 !important;
  }

  &-green {
    background: #64be5f !important;
  }

  &-gray {
    background: #b4b4b4 !important;
  }
}

input[type="date"] {
  color: black;
}

.preloader-fixed-content {
  width: 100%;
  display: flex;
  position: absolute;
  height: 100%;
}